@import url(https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@300;400;700&display=swap);
.content{
    --background: #e9edf2;
}

.practiceLogo{
    width: 85%;
	height: 125px;
    object-fit: contain;
    margin: auto;
}

.checkCircle{    
    width: 150px; 
    overflow: hidden;
    margin: auto;
}

.invoiceBadge{
    --background: #ebedf2;
    --color: #3c92fd;
    width: 50px;
    height: 50px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 1px;
    padding-left: 1px;
}

.patientNowLogo{
    width: 200px; 
    overflow: hidden;
    margin: auto;
    margin-bottom: 5px;  
}

.divVersion{
    width: 100%;
    text-align: center;
    color: #a0a5b9;
}

.imageBroken{
    margin-top: 50px;
    font-weight: bold;
    color: #231f20;
    letter-spacing: 0.3px;
}

h2{
    font-weight: 700 !important;
}

.padding-3{
    padding-top: 3px;
    padding-right: 0px;
    padding-bottom: 3px;
    padding-left: 0px;
    font-size: 13px !important;
}

.errorBox{
    --background: #fff2f0;
    border: 1px solid #fc3d3c;
}

.word-wrap {

    /* These are technically the same, but use both */
    overflow-wrap: break-word;
    word-wrap: break-word;
  
    -ms-word-break: break-all;
    
    word-break: break-word;
  
    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    -ms-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
  
  }

.transparentItem{
    --background: transparent;
}

.title{
    padding-top: 15px;
    padding-right: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    letter-spacing: 0.3px;
    color: #231f20;
}

.subtitle {
    font-size: 6.5px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.35px;
    text-align: center;
    color: #231f20;
    padding: 15px;
  }

  .not-found {
    font-size: 100px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.35px;
    color: #231f20;
    padding: 15px;
  }

.description{
    line-height: 1.67;
    letter-spacing: 0.3px;
    color: #231f20;
}

.description-small{
    font-weight: normal;
    letter-spacing: 0.24px;
    color: #a0a5b9;
}

.amounts{
    letter-spacing: 0.3px;
    text-align: right;
    color: #14d564;
}  

.sub-amounts{
    font-weight: normal;
    letter-spacing: 0.24px;
    color: #a0a5b9;
}

.boldH3{
    font-weight: 600 !important;
}

.item-active{
    border:1px solid #3c92fd;
    border-radius: 5px;
    --background:#dff2ff;    
}

.item-inactive{
    border:1px solid #ebedf2;
    border-radius: 5px;
}

.item-active-no-border{
    border-radius: 5px;
    --background:#dff2ff;    
}

.div-active{
    border: 1px solid #3c92fd;
    border-radius: 5px;
    background: #dff2ff;
}

.div-inactive{
    border:1px solid #ebedf2;
    border-radius: 5px;
}

.input-active{
    --background:#dff2ff;    
}

.fontSizeError{
    color: red;
    font-size: 12px;
}

.item-no-padding{
    /*padding: 0px 15px;*/
    --padding-start: 0px;
    --inner-padding-end: 0px;
}

.radio-ios-circle{
    border-radius: 10px;
    border: 1px solid #ccc;
    width: 23px;
    height: 23px;
}

.action-button{
    height:42px;
    font-size:13px;
    font-weight:bold;
}

.fa-button-icon{
    padding-right:5px;
}

ion-item.item-input.item-has-value > ion-label{
    font-weight: bold;
}

ion-item.item-input:not(.item-has-value, .item-has-focus) > ion-label{
    color: #A0A5B9 !important;
}

a:link {
text-decoration: none;
}

a:visited {
text-decoration: none;
}

a:hover {
text-decoration: underline;
}

a:active {
text-decoration: underline;
}

.marginInline {
    margin-inline: 0px;
    -webkit-margin-end: 0px;
            margin-inline-end: 0px;
    -webkit-margin-start: 0px;
            margin-inline-start: 0px;
}

@media only screen and (max-width: 992px) {    
    .bigScreenLogo{
        display: none;
      }

    .smallScreenLogo{
      display: block;
    }
  }

  @media only screen and (min-width: 992px) {    
    .smallScreenLogo{
        display: none;
      }
  }  
/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #3880ff;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
}

:root,
:root[mode="md"],
:root[mode="ios"]{  
  --ion-font-family:'Kumbh Sans', sans-serif !important;  
  font-family: var(--ion-font-family) !important;
}
